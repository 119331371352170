import { createPdf } from "pdfmake/build/pdfmake";

export class PdfService {

  pdfMake;

  constructor() {
  }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import("pdfmake/build/pdfmake");
      const pdfFontsModule = await import("pdfmake/build/vfs_fonts");
      this.pdfMake = pdfMakeModule;
      this.pdfMake.vfs = pdfFontsModule.pdfMake.vfs;
    }
  }

  // https://pdfmake.github.io/docs/0.1/ for more settings
  async generatePdf(filename, images) {
    await this.loadPdfMaker();
    let docDefinition = {
      content: []
    };
    for (const img of images) {
      let canvas = document.createElement("canvas");
      await canvas.renderImage(img);
      docDefinition.content.push({
        image: await toBase64(img),
        width: 500
      })
    }
    return createPdf(docDefinition);
  }
}

HTMLCanvasElement.prototype.renderImage = function(blob) {

  let ctx = this.getContext("2d");
  let img = new Image();

  img.onload = function() {
    ctx.drawImage(img, 0, 0);
  };

  img.src = URL.createObjectURL(blob);
};
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
